import React from 'react'
import {createPortal} from 'react-dom'
import {ProfileTitle, PullQuote, IntroText, Cta} from './Typography/Typography'
import CloseIcon from '../assets/img/close-white.svg'
import styled from 'styled-components'
import {useModalCEO, useWidth} from '../hooks'
import {breakpoints, mediaQueries} from '../utils'

const MessageCeo = ({onClick, ...props}) => {
  const {isModalOpenCEO, toggleModalCEO} = useModalCEO()
  const ProfileContainer = styled.div`
    display: flex;

    ${mediaQueries("max", "desktop")`
      align-items: center;
      flex-direction: column;
    `}

    .ceo-photos {
      border-radius: 50%;
      height: 180px;
      margin-right: 40px;
      object-fit: cover;
      width: 180px;
      ${mediaQueries("max", "desktop")`
        margin: 0;
      `}
    }

    .ceo-details {
      padding-top: 90px;
      ${mediaQueries("max", "desktop")`
        text-align: center;
        padding-top: 20px;
      `}
    }

    .ceo-details h2 {
      margin: 0;
    }

    .ceo-details p {
      margin: 0;
    }
  `;

  const InsideWrapper = styled.div`
    margin: 0 auto;
    width: calc(50% - 80px);

    &:first-of-type {
      margin-right: 80px;
    }

    ${mediaQueries("max", "desktop")`
      width: 100%;
      margin-bottom: 50px;

      &:first-of-type {
        margin-right: 0;
        z-index: 0;
      }
    `}

    @media (min-width: 1000px) and (max-width: 1240px) {
      margin: 90px auto;
      width: 80%;

      &:first-of-type {
        margin-right: auto;
        margin-bottom: 0;
      }
    }

    @media (max-height: 800px) and (min-width: 1241px) and (max-width: 1440px) {
      width: calc(100% - 50px);

      &:first-of-type {
        margin-right: 100px;
      }
    }
  `;

  const Overlay = styled.div`
    background: #331c54;
    top: 0;
    left: 0;
    bottom: 0;
    overflow: auto;
    position: fixed;
    right: 0;
    z-index: 9999;

    .close-icon {
      cursor: pointer;
      height: 70px;
      position: absolute;
      right: 40px;
      top: 40px;
      width: 70px;
      ${mediaQueries("max", "desktop")`
        height: 40px
    `}
    }

    a {
      color: #50c9b5;
      font-weight: 900;

      &:hover {
        color: #d1c7dc;
      }
    }

    .ceo-details h2 {
      margin: 0;
      margin-bottom: 10px;
    }

    .ceo-details p {
      margin: 0;
    }
  `;

  const Dialog = styled.div`
    ${mediaQueries("max", "desktop")`
      margin: 80px 20px;
    `}
    ${mediaQueries("min", "desktop")`
      margin: 100px auto;
      max-width: 1000px
    `}

    .flex-container {
      display: flex;
      ${mediaQueries("max", "desktop")`
        align-items: center;
        flex-direction: column;
        text-align: center;
      `}
      ${mediaQueries("min", "desktop")`
        align-items: flex-end;
      `}
    }

    .ceo-photos {
      width: 180px;
      height: 180px;
    }

    .ceo-details {
      ${mediaQueries("min", "desktop")`
        margin-left: 40px;
      `}
      h2,
      p {
        ${mediaQueries("max", "desktop")`
        margin: 10px 0
      `}
      }
    }
    .full-description {
      font-size: 18px;
      letter-spacing: 0;
      line-height: 30px;
    }

    p {
      color: white;
    }
  `;

  const ExcerptContainer = styled.div`
    .excerpt_intro {
      ${mediaQueries("min", "desktop")`
        margin-left: 50px;
      `}
      ${mediaQueries("min", "wide")`
        margin-left: 150px;
      `}
    }
  `;

  const width = useWidth();
  const isdesktop = width <= breakpoints.desktop;

  return (
    <>
      <InsideWrapper>
        {/* <Parallax x={!isdesktop ? [25, -5] : [0, 0]}> */}
        <ProfileContainer>
          <img className="ceo-photos" src={props.source} alt="CEO" />
          <div className="ceo-details">
            <ProfileTitle color="#331C54" size="20px">
              {props.data.name}
            </ProfileTitle>
            <p>{props.data.title}</p>
            <p>{props.data.position}</p>
          </div>
        </ProfileContainer>
        {/* </Parallax> */}
        <div>
          {!isdesktop ? (
            <ExcerptContainer>
              {/* <Parallax y={[-100, 100]}> */}
              <PullQuote color="#655784">{props.data.quote}</PullQuote>
              {/* </Parallax> */}
              {/* <Parallax y={[10, 35]}> */}
              <IntroText>{props.data.exercept}</IntroText>
              <div onClick={toggleModalCEO}>
                <Cta color="#50C9B5">Continue Reading</Cta>
              </div>
              {/* </Parallax> */}
            </ExcerptContainer>
          ) : (
            <>
              <div className="custom-class">
                <PullQuote color="#655784">{props.data.quote}</PullQuote>
              </div>
              <div className="custom-class">
                <IntroText>{props.data.exercept}</IntroText>
                <div onClick={toggleModalCEO}>
                  <Cta color="#50C9B5">Continue Reading</Cta>
                </div>
              </div>
            </>
          )}
        </div>
      </InsideWrapper>

      {isModalOpenCEO &&
        createPortal(
          <Overlay>
            <img
              onClick={() => toggleModalCEO()}
              className="close-icon"
              src={CloseIcon}
              alt="Close Modal"
            />
            <Dialog>
              <div className="flex-container">
                <img className="ceo-photos" src={props.source} alt="CEO" />
                <div className="ceo-details">
                  <ProfileTitle color="#FFFFFF">{props.data.name}</ProfileTitle>
                  <p>{props.data.position}</p>
                </div>
              </div>
              <PullQuote color="#FFFFFF">{props.data.quote}</PullQuote>
              <p className="modal-intro-text">{props.data.exercept}</p>
              <p
                className="full-description"
                dangerouslySetInnerHTML={{ __html: props.data.fullDescription }}
              ></p>
            </Dialog>
          </Overlay>,
          document.body
        )}
    </>
  );
};

export { MessageCeo };

