const breakpoints = {
  phone: 600,
  tablet: 1000,
  desktop: 1240,
  wide: 1920,
}

const mediaQueries = (type, key) => {
  return style =>
    type === 'min'
      ? `@media (min-width: ${breakpoints[key]}px) { ${style} }`
      : type === 'max'
      ? `@media (max-width: ${breakpoints[key]}px) { ${style} }`
      : ''
}

export {breakpoints, mediaQueries}
