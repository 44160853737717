import React from 'react'
import {createPortal} from 'react-dom'
import {Year, ProfileTitle, ProfileName} from '../Typography/Typography'
import {useModal, useWidth} from '../../hooks'
import {breakpoints} from '../../utils'
import {TimelineModalGrey} from './TimelineModalGrey'
import {TimelineModalPurple} from './TimelineModalPurple'
import {ImageBubble, InfoIcon, BottomLegend} from './TimelineBubbles.styles'

const TimelineBubbles = props => {
  const {isModalOpen, toggleModal} = useModal()
  const width = useWidth()
  const isTablet = width <= breakpoints.desktop

  return (
    <div className={props.className}>
      <div id={props.id} className="timeline-bubbles-wrapper">
        <div className="timeline-bubbles-container">
          <ImageBubble src={props.src} />
          {props.data.imageLengend ? (
            <ProfileName>{props.data.imageLengend}</ProfileName>
          ) : null}
          <div className="bubble-info">
            <InfoIcon onClick={toggleModal} />
            {props.data.year ? (
              <div>
                <Year color="#50C9B5">{props.data.year}</Year>
                <ProfileTitle color="#331C54" size="24px">
                  {props.data.yearCopy}
                </ProfileTitle>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
        {props.data.bottomLegend ? (
          <BottomLegend>{props.data.bottomLegend}</BottomLegend>
        ) : (
          ''
        )}
        {isModalOpen
          ? [
              props.data.purpleModal ? (
                ''
              ) : isTablet ? (
                createPortal(
                  <TimelineModalGrey
                    onClick={() => toggleModal()}
                    props={props}
                  />,
                  document.body,
                )
              ) : (
                <TimelineModalGrey
                  onClick={() => toggleModal()}
                  props={props}
                />
              ),
            ]
          : ''}
      </div>
      {isModalOpen
        ? [
            props.data.purpleModal
              ? createPortal(
                  <TimelineModalPurple
                    onClick={() => toggleModal()}
                    props={props}
                  />,
                  document.body,
                )
              : '',
          ]
        : ''}
    </div>
  )
}

export {TimelineBubbles}
