import {useState} from 'react'
import {useWidth} from '../hooks'
import {breakpoints} from '../utils'

const useModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const width = useWidth()
  const isMobile = width <= breakpoints.tablet

  const toggleScroll = () => {
    if (!isModalOpen && !isMobile) {
      document.body.style.overflowY = 'hidden'
    } else {
      document.body.style.overflowY = 'scroll'
    }
  }

  const toggleModal = () => {
    setIsModalOpen(val => !val)
    if (isMobile) {
      toggleScroll()
    }
  }

  return {
    isModalOpen,
    toggleModal,
  }
}

export default useModal
