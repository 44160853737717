import React from 'react'

const Pagination = ({active, anchor, children, key, onClick, disabled}) => {
  return (
    <li
      key={key}
      className={active ? 'active-pagination' : disabled ? 'disabled' : ''}
    >
      <a onClick={onClick} href={anchor}>
        {children}
      </a>
    </li>
  )
}

export {Pagination}
