import {useState} from 'react'

const useActiveYear = () => {
  const [yearActive, setYearActive] = useState(2001)

  const yearsPagination = [
    {
      year: 2001,
      active: yearActive !== 2001 ? false : true,
      anchor: 'https://msfhr20.org/timeline/#id-2001',
      disabled: false,
    },
    {
      year: 2003,
      active: yearActive !== 2003 ? false : true,
      anchor: 'https://msfhr20.org/timeline/#id-2003',
      disabled: false,
    },
    {
      year: 2005,
      active: yearActive !== 2005 ? false : true,
      anchor: 'https://msfhr20.org/timeline/#id-2005',
      disabled: false,
    },
    {
      year: 2006,
      active: yearActive !== 2006 ? false : true,
      anchor: 'https://msfhr20.org/timeline/#id-2006',
      disabled: false,
    },
    {
      year: 2008,
      active: yearActive !== 2008 ? false : true,
      anchor: 'https://msfhr20.org/timeline/#id-2008',
      disabled: false,
    },
    {
      year: 2009,
      active: yearActive !== 2009 ? false : true,
      anchor: 'https://msfhr20.org/timeline/#id-2009',
      disabled: false,
    },
    {
      year: 2010,
      active: yearActive !== 2010 ? false : true,
      anchor: 'https://msfhr20.org/timeline/#id-2010',
      disabled: false,
    },
    {
      year: 2012,
      active: yearActive !== 2012 ? false : true,
      anchor: 'https://msfhr20.org/timeline/#id-2012',
      disabled: false,
    },
    {
      year: 2013,
      active: yearActive !== 2013 ? false : true,
      anchor: 'https://msfhr20.org/timeline/#id-2013',
      disabled: false,
    },
    {
      year: 2016,
      active: yearActive !== 2016 ? false : true,
      anchor: 'https://msfhr20.org/timeline/#id-2016',
      disabled: false,
    },
    {
      year: 2017,
      active: yearActive !== 2017 ? false : true,
      anchor: 'https://msfhr20.org/timeline/#id-2017',
      disabled: false,
    },
    {
      year: 2018,
      active: yearActive !== 2018 ? false : true,
      anchor: 'https://msfhr20.org/timeline/#id-2018',
      disabled: false,
    },
    {
      year: 2020,
      active: yearActive !== 2020 ? false : true,
      anchor: 'https://msfhr20.org/timeline/#id-2020',
      disabled: false,
    }
  ]

  return {
    setYearActive,
    yearActive,
    yearsPagination,
  }
}

export default useActiveYear
