import styled from 'styled-components'
import Info from '../../assets/img/info.svg'

const ImageBubble = styled.img``

const InfoIcon = styled.a`
  background-image: url(${Info});
  background-position: center;
  background-size: cover;
  cursor: pointer;
  display: block;
  height: 35px;
  width: 35px;
`

const BottomLegend = styled.p`
  bottom: -10%;
  position: absolute;
  margin: 0;
  right: 0;
  max-width: 145px;
  font-size: 14px !important;
  line-height: 20px !important;
  text-align: center;
  @media (max-width: 1000px) {
    position: unset;
    max-width: unset;
    margin-top: 15px;
  }
  @media (max-width: 1241px) {
    right: -35px;
  }
  // @media (min-width: 1000px) and (max-width: 1395px) {
  //   bottom: unset;
  // }
`

export {ImageBubble, InfoIcon, BottomLegend}
