import React, {useEffect, useState} from 'react'
import {animated, config, useSpring} from 'react-spring'
import {Spring} from 'react-spring/renderprops'
import {useHistory} from 'react-router-dom'
import styled from 'styled-components'
import {useAnimation, useHome, useWidth} from '../hooks'
import {Navigation} from '../components'
import {breakpoints, mediaQueries} from '../utils'
import Logo from '../assets/img/loading.gif'
import LogoMobile from '../assets/img/mobile-loading.gif'
import LogoAnniversary from '../assets/img/msfhr-20-logo.svg'
import LogoAnniversaryMobile from '../assets/img/msfhr-20-logo-mobile.png'

console.clear()

const AnimationContainer = styled.div`
  height: 100%;
  .container-animation:not(:last-of-type) {
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
  }

  .container-animation-mobile {
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
    img {
      transition: 0.5s ease-in-out;
    }
    ${mediaQueries('max', 'phone')`
      img {
        width: 250px;
      }
    `}
  }

  .intro-logo {
    ${mediaQueries('max', 'phone')`
      width: 80%;
      img {
        width: 100%;
      }
    `}
  }

  .container-animation-mobile_completed {
    display: block;
    left: 10px;
    position: fixed !important;
    // top: 17px !important;
    // top: 50px !important;
    transition: all 1s ease-in-out;
    -webkit-backface-visibility: hidden;
    z-index: 999;
    img {
      transition: all 0.1 ease-out;
    }
  }

  .animation-anniversary {
    img {
      transition: all 1s ease-in-out;
    }
  }

  img {
    width: 500px;
  }

  p {
    color: #5d6061;
    font-size: 18px;
    font-family: 'neo-sans', 'Helvetica Neue', sans-serif;
    letter-spacing: 0;
    line-height: 26px;
  }

  .flex-container {
    display: flex;
  }

  .scroll-down {
    bottom: 15px;
    cursor: pointer;
    height: 35px;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    width: 55px;
  }

  .logo-completed {
    width: 265px;
  }

  .logo-completed-mobile {
    left: 30px;
    width: 100px;
  }
  .hide {
    display: none;
  }
  .show {
    display: block;
  }
`

const PhoneContainer = styled.div`
  background-color: #fff;
  height: 100px;
  position: fixed;
  // width: 100%;
  z-index: 1;
`

const Animation = ({children, onComplete}) => {
  const {state, dispatch} = useAnimation()
  const {hasCompletedAnimation, onAnimationComplete} = useHome()
  const [showLogo, setShowLogo] = useState(false)
  const [showLogoAnniversary, setShowLogoAnniversary] = useState(false)
  const [showContent, setShowContent] = useState(false)
  const [offsetLogo, setOffsetLogo] = useState(false)

  const ANIMATION_FADE_IN_DURATION = 3000
  const ANIMATION_FADE_OUT_DURATION = 1500

  const width = useWidth()

  const isMobile = width <= breakpoints.tablet

  const fadeLogo = useSpring({
    config: {...config.slow},
    from: {
      opacity: 0,
    },
    to: {
      opacity: showLogo ? 1 : 0,
    },
  })

  const fadeInContent = useSpring({
    config: {...config.slow},
    from: {
      opacity: 0,
    },
    to: {
      opacity: showContent ? 1 : 0,
    },
  })

  const history = useHistory()

  const goTimelinePage = () => {
    history.push('/timeline')
  }

  useEffect(() => {
    switch (state) {
      case 'IMAGE_ONE_FADING_IN':
        setShowLogo(true)
        setTimeout(() => {
          dispatch('DONE')
        }, ANIMATION_FADE_IN_DURATION)
        break
      case 'IMAGE_ONE_FADING_OUT':
        setShowLogo(false)
        setTimeout(() => {
          dispatch('DONE')
        }, ANIMATION_FADE_OUT_DURATION / 3)
        break
      case 'IMAGE_TWO_FADING_IN':
        setShowLogoAnniversary(true)
        setTimeout(() => {
          setOffsetLogo(true)
          dispatch('DONE')
        }, ANIMATION_FADE_IN_DURATION)
        break
      case 'IMAGE_TWO_OFFSET_TOP':
        onAnimationComplete()
        setTimeout(() => {
          // NOTE: tentative disabling for routing
          // onComplete()
          dispatch('DONE')
        }, ANIMATION_FADE_OUT_DURATION / 1.5)
        break
      case 'ANIMATION_FADING_OUT':
        setShowContent(true)
        goTimelinePage()
        setTimeout(() => {
          dispatch('DONE')
        }, ANIMATION_FADE_OUT_DURATION / 2)
        break
      default:
        break
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, dispatch, onComplete])

  const toggleNavStateEffects = () => {
    const logo = document.querySelector('.container-animation-mobile_completed')

    let hidden = document
      .querySelector('.bm-menu-wrap')
      .getAttribute('aria-hidden')

    if (hidden === 'false') {
      logo.classList.add('hide')
      document.body.style.overflowY = 'hidden'
    } else {
      logo.classList.remove('hide')
      document.body.style.overflowY = 'visible'
    }
  }

  return (
    <AnimationContainer className="container-animation">
      <animated.div className="container-animation intro-logo" style={fadeLogo}>
        <img src={isMobile ? LogoMobile : Logo} alt="" />
      </animated.div>
      <Spring
        from={{
          opacity: 0,
          position: 'absolute',
          top: '50%',
        }}
        to={{
          opacity: showLogoAnniversary ? 1 : 0,
          top: offsetLogo ? 64 : '',
          transition: 'all 1s ease-in-out',
        }}
      >
        {props => (
          <div
            className={
              !isMobile
                ? 'container-animation animation-anniversary'
                : hasCompletedAnimation
                ? 'container-animation-mobile_completed animation-anniversary'
                : 'container-animation-mobile animation-anniversary'
            }
            style={props}
          >
            <a style={{cursor: 'pointer'}} href="/">
              <img
                className={
                  hasCompletedAnimation
                    ? isMobile
                      ? 'logo-anniversary logo-completed-mobile'
                      : 'logo-anniversary logo-completed'
                    : 'logo-anniversary'
                }
                src={isMobile ? LogoAnniversaryMobile : LogoAnniversary}
                alt="Michael Smith Foundation for Health Research 20th Anniversary Campaign Logo"
              />
            </a>
          </div>
        )}
      </Spring>

      {showContent && isMobile ? (
        <PhoneContainer>
          <Navigation onStateChange={toggleNavStateEffects} />
        </PhoneContainer>
      ) : (
        ''
      )}

      <animated.div className="container-animation" style={fadeInContent} />
      {/* {children}
      </animated.div> */}
    </AnimationContainer>
  )
}

export default Animation
