import React from 'react'
import CloseIcon from '../../assets/img/close-grey.svg'
import Pointer from '../../assets/img/factoid_pointer.svg'

const TimelineModalGrey = ({onClick, props}) => {
  return (
    <>
      <div className="timeline-grey-modal">
        <img
          onClick={onClick}
          className="close-icon"
          src={CloseIcon}
          alt="Close Modal"
        />
        <div className="inner-grey-modal">
          <h3>{props.data.modalTitle}</h3>
          <div
            className="modal-content"
            dangerouslySetInnerHTML={{__html: props.data.modalContent}}
          ></div>
        </div>
        <img className="pointer" src={Pointer} alt="Dialog Box Icon" />
      </div>
    </>
  )
}

export {TimelineModalGrey}
