import React, { useEffect } from "react";
import CloseIcon from "../../assets/img/close-white.svg";

const TimelineModalPurple = ({ onClick, props }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="timeline-purple-modal">
        <img
          onClick={onClick}
          className="close-icon"
          src={CloseIcon}
          alt="Close Modal"
        />
        <div className="inner-purple-modal">
          <h3>{props.data.modalTitle}</h3>
          <p
            className="intro-text"
            dangerouslySetInnerHTML={{ __html: props.data.modalIntroText }}
          ></p>
          <div
            className="modal-content"
            dangerouslySetInnerHTML={{ __html: props.data.modalContent }}
          ></div>
          {props.data.callOutBoxContent ? (
            <div
              dangerouslySetInnerHTML={{ __html: props.data.callOutBoxContent }}
            ></div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export { TimelineModalPurple };
