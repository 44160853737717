import React, {useState} from 'react'
import styled from 'styled-components'
import {slide as Menu} from 'react-burger-menu'
import {Pagination} from '../../components'
import {useActiveYear} from '../../hooks'
import NavLogo from '../../assets/img/hamburger-icon.svg'
import NavLogoClose from '../../assets/img/menu-close.svg'
import Facebook from '../../assets/img/facebook.svg'
import Linkedin from '../../assets/img/linkedin.svg'
import Twitter from '../../assets/img/twitter.svg'

const styles = {
  bmBurgerButton: {
    cursor: 'pointer',
    position: 'fixed',
    width: '36px',
    height: '45px',
    right: '35px',
    top: '50px',
    transform: 'translateY(-50%)',
    transition: 'all 2s ease-in-out',
    zIndex: 999,
  },
  bmCrossButton: {
    cursor: 'pointer',
    height: '50px',
    right: '18px',
    top: '5px',
    width: '36px',
  },
  bmMenuWrap: {
    position: 'fixed',
    height: '100%',
    width: '100%',
  },
  bmMenu: {
    background: '#FFF',
    padding: '2.5em 0 85px 1.5em',
    fontSize: '1.15em',
    overflow: 'hidden',
  },
  bmMorphShape: {
    fill: '#373a47',
  },
  bmItemList: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '40px',
    borderTop: '2px solid #CD202C',
  },
  bmItem: {
    display: 'inline-block',
    listStyle: 'none',
    margin: 0,
    marginTop: 35,
    outline: 'none',
    padding: 0,
  },
  bmOverlay: {
    background: '#FFF',
    zIndex: 'unset',
  },
  bmIcon: {
    width: 50,
    transition: 'all 2s ease-in-out',
  },
  bmCross: {
    width: 31,
  },
}

const ShareContainer = styled.div`
  align-items: center;
  background: #fff;
  display: flex;
  left: 1.5em;
  position: absolute;
  top: 15px;
`

const SocialMediaIcon = styled.a`
  img {
    height: 30px;
    margin: 0 10px;
    width: 30px !important;

    &:hover {
      animation: shake 10.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both infinite;
      transform: translate3d(0, 0, 0);
      backface-visibility: hidden;
      perspective: 1000px;
    }

    @keyframes shake {
      .76%,
      6.84% {
        transform: translate3d(-1px, 0, 0);
      }
      1.52%,
      6.08% {
        transform: translate3d(2px, 0, 0);
      }
      2.28%,
      3.8%,
      5.32% {
        transform: translate3d(-4px, 0, 0);
      }
      3.04%,
      4.56% {
        transform: translate3d(4px, 0, 0);
      }
    }
  }
`

const ShareCopy = styled.p`
  color: #cd202c !important;
  font-family: 'interstate-condensed', 'Helvetica Neue', sans-serif;
  font-size: 15px !important;
  font-weight: 900;
  letter-spacing: 0 !important;
  line-height: 18px !important;
  text-align: center !important;
  margin: 0;
`

const StyledList = styled.ul`
  li {
    font-size: 36px;
    font-weight: 900;
    letter-spacing: 0;
    line-height: 56px;
  }

  li a {
    color: #331c54;
    text-decoration: none;
  }
`

const StyledLink = styled.a`
  bottom: 40px;
  color: #cd202c;
  font-weight: bold;
  left: 50%;
  position: absolute;
  text-decoration: none;
  transform: translateX(-50%);
`

const Navigation = ({onStateChange}) => {
  const {setYearActive, yearsPagination} = useActiveYear()
  const [menuOpen, setMenuOpen] = useState(false)

  const handleMenuState = () => {
    setMenuOpen(val => !val)
  }

  const toggleMenuState = () => {
    setMenuOpen(false)
  }

  return (
    <Menu
      isOpen={menuOpen}
      onStateChange={onStateChange}
      customBurgerIcon={
        <img
        onClick={() => {
          handleMenuState()
        }}
        src={NavLogo}
        alt=''
        />
      }
      customCrossIcon={
        <img
        onClick={() => {
          handleMenuState()
        }}
        src={NavLogoClose}
        alt=''
        />
      }
      styles={styles}
      right
    >
      <StyledList>
        <ShareContainer>
          <ShareCopy>SHARE:</ShareCopy>
          <SocialMediaIcon
            href="https://twitter.com/intent/tweet?text=MSFHR+20+Years+Anniversary&url=https://msfhr20.org"
            target="_blank"
          >
            <img src={Twitter} alt="Twitter" />
          </SocialMediaIcon>
          <SocialMediaIcon
            href="https://www.facebook.com/sharer/sharer.php?u=https://msfhr20.org/"
            target="_blank"
          >
            <img src={Facebook} alt="Facebook" />
          </SocialMediaIcon>
          <SocialMediaIcon
            href="https://www.linkedin.com/sharing/share-offsite/?url=https://msfhr20.org"
            target="_blank"
          >
            <img src={Linkedin} alt="Linkedin" />
          </SocialMediaIcon>
        </ShareContainer>

        {yearsPagination.map(year => (
          <Pagination
            onClick={() => {
              setYearActive(year.year)
              toggleMenuState()
            }}
            anchor={year.anchor}
            active={year.active}
            disabled={year.disabled}
          >
            {year.year}
          </Pagination>
        ))}
      </StyledList>
      <StyledLink href="https://www.msfhr.org" target="_blank">
        MSFHR HOME
      </StyledLink>
    </Menu>
  )
}

export default Navigation

