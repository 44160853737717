import {useState} from 'react'

const useHome = () => {
  const [hasCompletedAnimation, setHasCompletedAnimation] = useState(false)

  const onAnimationComplete = () => {
    setHasCompletedAnimation(true)
    document.body.style.overflowX = 'hidden'
  }

  return {
    hasCompletedAnimation,
    onAnimationComplete,
  }
}

export default useHome
