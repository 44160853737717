import useMachine from 'react-use-machine'
import animationMachine from './state-machine'

const useAnimation = () => {
  const {state, event, dispatch} = useMachine(
    animationMachine,
    'IMAGE_ONE_FADING_IN',
  )

  return {
    state,
    event,
    dispatch,
  }
}

export default useAnimation
