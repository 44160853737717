const animationMachine = {
  IMAGE_ONE_FADING_IN: {
    DONE: 'IMAGE_ONE_FADING_OUT',
  },
  IMAGE_ONE_FADING_OUT: {
    DONE: 'IMAGE_TWO_FADING_IN',
  },
  IMAGE_TWO_FADING_IN: {
    DONE: 'IMAGE_TWO_OFFSET_TOP',
  },
  IMAGE_TWO_OFFSET_TOP: {
    DONE: 'ANIMATION_FADING_OUT',
  },
}

export default animationMachine
