import React from 'react'
import styled from 'styled-components'
import {mediaQueries} from '../utils'
import Facebook from '../assets/img/facebook.svg'
import Linkedin from '../assets/img/linkedin.svg'
import Twitter from '../assets/img/twitter.svg'

const SideNavigation = () => {
  const SideNavigationWrapper = styled.section`
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 70px;
    z-index: 999;

    @media (max-width: 1000px) {
      display: none;
    }

    ${mediaQueries('min', 'desktop')`
      left: 30px;
      position: absolute
    `}
  `

  const ToSiteBtn = styled.a`
    align-items: center;
    background: #cd202c;
    border-radius: 50%;
    box-sizing: border-box;
    color: white;
    display: flex;
    font-family: 'interstate-condensed', 'Helvetica Neue', sans-serif;
    font-size: 16px;
    font-weight: 900;
    height: 66px;
    text-decoration: none;
    letter-spacing: 0;
    text-align: center;
    width: 66px;

    &:hover {
      background: rgba(205, 32, 44, 0.6);
      transition: background ease 0.3s;
    }
  `

  const SocialMediaIcon = styled.a`
    img {
      height: 30px;
      margin-bottom: 15px;
      width: 30px;

      &:hover {
        animation: shake 10.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both
          infinite;
        transform: translate3d(0, 0, 0);
        backface-visibility: hidden;
        perspective: 1000px;
      }

      @keyframes shake {
        .76%,
        6.84% {
          transform: translate3d(-1px, 0, 0);
        }
        1.52%,
        6.08% {
          transform: translate3d(2px, 0, 0);
        }
        2.28%,
        3.8%,
        5.32% {
          transform: translate3d(-4px, 0, 0);
        }
        3.04%,
        4.56% {
          transform: translate3d(4px, 0, 0);
        }
      }
    }
  `

  const ShareCopy = styled.p`
    color: #cd202c !important;
    font-family: 'interstate-condensed', 'Helvetica Neue', sans-serif;
    font-size: 15px !important;
    font-weight: 900;
    letter-spacing: 0 !important;
    line-height: 18px !important;
    text-align: center !important;
    margin-top: 60px;
    margin-bottom: 30px;
  `

  return (
    <SideNavigationWrapper>
      <ToSiteBtn href="https://www.msfhr.org/" target="_blank">
        MSFHR HOME
      </ToSiteBtn>
      <ShareCopy>SHARE</ShareCopy>
      <SocialMediaIcon
        href="https://twitter.com/intent/tweet?text=MSFHR+turns+20&url=https://msfhr20.org"
        target="_blank"
      >
        <img src={Twitter} alt="Twitter" />
      </SocialMediaIcon>
      <SocialMediaIcon
        href="https://www.facebook.com/sharer/sharer.php?u=https://msfhr20.org/"
        target="_blank"
      >
        <img src={Facebook} alt="Facebook" />
      </SocialMediaIcon>
      <SocialMediaIcon
        href="https://www.linkedin.com/sharing/share-offsite/?url=https://msfhr20.org"
        target="_blank"
      >
        <img src={Linkedin} alt="Linkedin" />
      </SocialMediaIcon>
    </SideNavigationWrapper>
  )
}

export {SideNavigation}
