import React from 'react'
import styled from 'styled-components'
import {Navigation} from '../components'
import {breakpoints, mediaQueries} from '../utils'
import {useWidth} from '../hooks'
import LogoAnniversary from '../assets/img/msfhr-20-logo.svg'
import LogoAnniversaryMobile from '../assets/img/msfhr-20-logo-mobile.png'

const NavContainer = styled.div`
  background-color: #fff;
  ${mediaQueries('max', 'phone')`
    height: 100px;
    position: fixed;
    z-index: 999;
  `};
`

const LogoContainer = styled.div`
  left: 50%;
  opacity: 1;
  position: absolute;
  // transform: translate(-50%, -50%);
  transform: translateX(-50%);
  transition: all 1s ease-in-out 0s;
  top: 64px;
  ${mediaQueries('max', 'phone')`
    top: 0 !important;
    transform: translateX(-50%);
  `};
`

const StyledLink = styled.a`
  cursor: pointer;
  background-color: transparent;
`

const StyledIMG = styled.img`
  transition: all 1s ease-in-out 0s;
  width: 265px;
`

const PhoneContainer = styled.div`
  background-color: #fff;
  left: 0;
  z-index: 1;
`

const toggleNavStateEffects = () => {
  const logo = document.querySelector('.container-animation-mobile_completed')

  let hidden = document
    .querySelector('.bm-menu-wrap')
    .getAttribute('aria-hidden')

  if (hidden === 'false') {
    logo.classList.add('hide')
    document.body.style.overflowY = 'hidden'
  } else {
    logo.classList.remove('hide')
    document.body.style.overflowY = 'visible'
  }
}

const Header = () => {
  const width = useWidth()
  const isMobile = width <= breakpoints.tablet

  return (
    <NavContainer>
      <LogoContainer
        className={
          !isMobile
            ? 'container-animation animation-anniversary'
            : 'container-animation-mobile_completed animation-anniversary'
        }
      >
        <StyledLink>
          <StyledIMG
            className={
              isMobile
                ? 'logo-anniversary logo-completed-mobile'
                : 'logo-anniversary logo-completed'
            }
            src={isMobile ? LogoAnniversaryMobile : LogoAnniversary}
            alt="Michael Smith Foundation for Health Research 20th Anniversary Campaign Logo"
          />
        </StyledLink>
      </LogoContainer>
      {isMobile ? (
        <PhoneContainer>
          <Navigation onStateChange={toggleNavStateEffects} />
        </PhoneContainer>
      ) : (
        ''
      )}
    </NavContainer>
  )
}

export {Header}
