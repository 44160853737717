import styled from "styled-components";
import OpenQuote from "../../assets/img/open-quote.svg";
import CloseQuote from "../../assets/img/close-quote.svg";
import { mediaQueries } from "../../utils";

const StyledProfileTitle = styled.h2`
  color: ${(props) => props.color};
  font-size: ${(props) => props.size};
  font-weight: bold;
  letter-spacing: 0;
  line-height: 32px;
  @media screen and (max-width: 1460px) {
    font-size: 20px;
  }
`;

const StyledProfileName = styled.p`
  bottom: 5px;
  color: #fff !important;
  font-size: 16px;
  font-weight: bold;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  white-space: nowrap;
  @media screen and (max-width: 1460px) {
    font-size: 20px;
  }
`;

const StyledIntroText = styled.p`
  font-size: 18px !important;
  line-height: 26px !important;

  @media (max-height: 1000px) and (min-width: 1241px) and (max-width: 1600px) {
    display: none;
  } ;
`;

const StyledCta = styled.a`
  cursor: pointer;
  color: ${(props) => props.color};
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 26px;
`;

const StyledPullQuote = styled.h2`
  color: ${(props) => props.color};
  font-family: "interstate-condensed";
  font-weight: 900;
  letter-spacing: 0;
  margin-bottom: 0;
  position: relative;
  z-index: 1;

  @media (max-width: 1300px) {
    font-size: 36px;
    line-height: 44px;
    text-indent: 0;
  }

  ${mediaQueries("min", "desktop")`
    font-size: 48px;
    line-height: 55px;
    text-indent: 180px;
  `};

  @media (max-height: 800px) and (min-width: 1241px) and (max-width: 1440px) {
    font-size: 38px;
    line-height: 50px;
    margin-top: 20px;
    margin-bottom: 35px;
  }

  @media (min-width: 1880px) {
    max-width: 90%;
  }

  &::before {
    content: url(${OpenQuote});
    left: -30px;
    position: absolute;
    z-index: -1;
    ${mediaQueries("max", "tablet")`
      display: none;
    `}
  }

  &::after {
    content: url(${CloseQuote});
    z-index: -5;
    margin-left: -20px;
    position: relative;
    ${mediaQueries("max", "tablet")`
      display: none;
    `}
  }
`;

const StyledYear = styled.a`
  color: ${(props) => props.color};
  font-family: "interstate-condensed";
  font-size: 70px;
  letter-spacing: 0;
  line-height: 83px;
  font-weight: 900;
  @media screen and (max-width: 1460px) {
    font-size: 48px;
  }
  @media screen and (max-width: 1000px) {
    font-size: 70px;
  }
`;

export {
  StyledProfileTitle,
  StyledProfileName,
  StyledPullQuote,
  StyledIntroText,
  StyledCta,
  StyledYear,
};
