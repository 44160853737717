import {useState} from 'react'
import {useWidth} from '../hooks'
import {breakpoints} from '../utils'

const useModalCEO = () => {
  const [isModalOpenCEO, setIsModalOpenCEO] = useState(false)
  const width = useWidth()
  const isMobile = width <= breakpoints.tablet

  const toggleScroll = () => {
    if (!isModalOpenCEO && !isMobile) {
      document.body.style.overflowY = 'hidden'
    } else if (isModalOpenCEO && isMobile) {
      document.body.style.overflowY = 'hidden'
    } else {
      document.body.style.overflowY = 'scroll'
    }
  }

  const toggleModalCEO = () => {
    setIsModalOpenCEO(val => !val)
    toggleScroll()
  }

  return {
    isModalOpenCEO,
    toggleModalCEO,
  }
}

export default useModalCEO
