import {BrowserRouter as Router, Route} from 'react-router-dom'
import {Wrapper as WrapperGlobalStyles} from './components'
import {Animation, Home} from './pages'

const homeState = {
  name: 'home',
  url: '/home',
  component: Home,
}

const App = () => {
  return (
    <Router>
      <div className="App">
        <WrapperGlobalStyles>
          <Route path="/" exact component={Animation} />
          <Route path="/timeline" component={Home} />
        </WrapperGlobalStyles>
      </div>
    </Router>
  )
}

export {homeState}
export default App

