import React from 'react'
import {
  StyledProfileTitle,
  StyledProfileName,
  StyledPullQuote,
  StyledIntroText,
  StyledCta,
  StyledYear,
} from './Typography.styles.js'

const ProfileTitle = ({children, color, size}) => (
  <StyledProfileTitle color={color} size={size}>
    {children}
  </StyledProfileTitle>
)

const ProfileName = ({children, color, size}) => (
  <StyledProfileName color={color} size={size}>
    {children}
  </StyledProfileName>
)

const PullQuote = ({children, color}) => (
  <StyledPullQuote color={color}>{children}</StyledPullQuote>
)

const IntroText = ({children}) => <StyledIntroText>{children}</StyledIntroText>

const Cta = ({children, color}) => (
  <StyledCta color={color}>{children}</StyledCta>
)

const Year = ({children, color}) => (
  <StyledYear color={color}>{children}</StyledYear>
)

export {ProfileTitle, ProfileName, PullQuote, IntroText, Cta, Year}
