import React, {useState, useRef} from 'react'
import FirstCeoImage from '../assets/img/Bev _Holmes.png'
import SecondCeoImage from '../assets/img/Elinor_Wilson.png'
import ScrollDown from '../assets/img/scroll_down.svg'
import BubbleBg from '../assets/img/background-pattern.png'
import {MessageCeo, Header, Timeline} from '../components/index'
import '../App'
import styled from 'styled-components'
import {mediaQueries} from '../utils'
import {data} from '../assets/data/ceomessagedata'
import {useModal, useModalCEO} from '../hooks'

const HomeOverallWrapper = styled.div`
  background-image: url(${BubbleBg});
  background-size: cover;

  ${mediaQueries('max', 'tablet')`
    background-position: top;
  `};
`

const Wrapper = styled.div`
  display: flex;
  margin-top: 60px;

  ${mediaQueries('max', 'desktop')`
      flex-direction: column;
      margin: 90px 0;
      z-index: -5;
    `};

  @media (max-height: 800px) and (min-width: 1241px) and (max-width: 1440px) {
    margin-top: 50px;
  }
`

const FirstSectionWrapper = styled.section`
  box-sizing: border-box;
  height: 100vh;
  padding: 30px 60px;
  padding-top: 90px;
  position: relative;

  ${mediaQueries('max', 'desktop')`
      // background: linear-gradient(0deg, rgba(237,240,242,1) 0%, rgba(237,240,242,0) 100%);
      height: auto;
      padding: 30px 15px;
      padding-bottom: 35px;
    `};

  ${mediaQueries('min', 'desktop')`
      margin-bottom: 150px
    `};

  @media (min-width: 1950px) {
    max-width: 1950px;
    margin: 0 auto;
  }
`

const OurStory = styled.h2`
  color: #cd202c;
  font-size: 42px;
  margin: 0 auto;
  width: max-content;
  ${mediaQueries('min', 'tablet')`
    display: none;
  `};
`

const Home = () => {
  const [isModalOpen1, setIsModalOpen1] = useState(false)
  const [isModalOpen2, setIsModalOpen2] = useState(false)

  const {isModalOpenCEO} = useModalCEO()
  const {isModalOpen} = useModal()

  const toggleModal1 = () => {
    setIsModalOpen1(val => !val)
  }

  const toggleModal2 = () => {
    setIsModalOpen2(val => !val)
  }

  let FirstCeoProps = {
    source: SecondCeoImage,
    data: data[0].FirstCeo,
    onStateChange: toggleModal1,
    isModalOpen: isModalOpen1,
  }

  let SecondCeoProps = {
    source: FirstCeoImage,
    data: data[0].SecondCeo,
    onStateChange: toggleModal2,
    isModalOpen: isModalOpen2,
  }

  const useScroll = () => {
    const elRef = useRef(null)
    const executeScroll = () =>
      elRef.current.scrollIntoView({behavior: 'smooth', block: 'end'})

    return [executeScroll, elRef]
  }

  const [executeScroll, elRef] = useScroll()

  return (
    <HomeOverallWrapper>
      <>
        <FirstSectionWrapper>
          <Header></Header>
          <Wrapper>
            <MessageCeo {...FirstCeoProps} />
            <MessageCeo {...SecondCeoProps} />
          </Wrapper>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              marginTop: '50px',
            }}
          >
            <img
              className="scroll-down"
              src={ScrollDown}
              alt="Scroll Down Icon"
              onClick={executeScroll}
            />
          </div>
        </FirstSectionWrapper>
        {!isModalOpenCEO || !isModalOpen ? (
          <div className="timeline-wrapper" ref={elRef}>
            <OurStory id="story">Our History</OurStory>
            <Timeline />
          </div>
        ) : (
          ''
        )}
      </>
    </HomeOverallWrapper>
  )
}

export default Home

