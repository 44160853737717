import {useEffect, useState} from 'react'

const getWidth = () =>
  window.innerWidth ||
  document.documentElement.clientWidth ||
  document.body.clientWidth

const useWidth = () => {
  const [width, setWidth] = useState(getWidth())

  useEffect(() => {
    let timeout = null
    if (getWidth() >= 1000) {
      document.body.style.overflowY = 'scroll'
    }
    const windowListener = () => {
      clearTimeout(timeout)
      timeout = setTimeout(() => setWidth(getWidth()), 150)
    }
    window.addEventListener('resize', windowListener)

    return () => {
      window.removeEventListener('resize', windowListener)
    }
  }, [])

  return width
}

export default useWidth
