import React from 'react'
import {createGlobalStyle} from 'styled-components'
import {normalize} from 'polished'
import {mediaQueries} from '../../utils'

const GlobalStyle = createGlobalStyle`
  ${normalize()}

  html {
    height: 100%;
  }

  body {
    height: 100%;
    margin: 0;
    padding: 0;
  }

  .bm-menu-wrap {
    ${mediaQueries('min', 'phone')`
      width: 55% !important;
    `};
    ${mediaQueries('max', 'phone')`
      width: 100% !important;
    `};
  }

  #react-burger-menu-btn, #react-burger-cross-btn {
    z-index: -999 !important;
  }

`

const Wrapper = ({children}) => {
  return (
    <>
      <GlobalStyle />
      <main>{children}</main>
    </>
  )
}

export default Wrapper
